import React from 'react'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import EmailIcon from '@material-ui/icons/Email'
import Zoom from '@material-ui/core/Zoom'

import Alert from 'react-s-alert'
import {withStyles} from '@material-ui/core/styles'

import {
  get,
  set
} from 'idb-keyval'

import {
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting,
  SearchState,
  RowDetailState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  DragDropProvider,
  TableFixedColumns,
  TableColumnReordering,
  TableColumnResizing,
  ColumnChooser,
  TableColumnVisibility,
  Toolbar,
  SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';

import SendPayEmailDialog from './dialogs/SendPayEmailDialog'
import DeleteOrderDialog from './dialogs/DeleteOrderDialog'
import RowDetail from './RowDetail'


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#000c',
    boxShadow: theme.shadows[1],
    border: '1px solid #bbbc',
    fontSize: 11,
  },

})

export const tableMessages = {
  noData: 'Нет данных'
}

export const pagingPanelMessages = {
  showAll: 'Все',
  rowsPerPage: 'Заказов на странице',
  info: 'Заказы {from} - {to} (Всего {count})',
}

export const summaryRowMessages = {
  count: 'Всего',
  sum: 'Всего',
  min: 'Мин',
  max: 'Макс',
  avg: 'Средн',
}


export function OrderTooltip(props) {
  const {
    children,
    classes,
    ...other
  } = props

  return <Tooltip
    enterDelay={300}
    leaveDelay={300}
    TransitionComponent={Zoom}
    classes={{tooltip: classes.lightTooltip}}
    {...other}
  >
    {children}
  </Tooltip>
}

export function Loading() {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'inline-block',
        background: 'url(assets/components/modxsite/templates/v5/img/loading.gif) no-repeat',
        backgroundSize: 'contain',
        left: '50%',
        top: '50%',
        width: 64,
        height: 64,
        transform: 'translate(-50%,-50%)',
        zIndex: 10000,
      }}
    />
  )
}

const compareCurrency = (a, b) => {

  a = parseInt(a.replace(/\s/g, ''))
  b = parseInt(b.replace(/\s/g, ''))

  if (a === b)
    return 0

  return (a < b) ? -1 : 1
};

class AdminOrders extends React.PureComponent {

  constructor(props) {

    super(props)

    this.state = {
      cfg: window.appConfig,
      columns:
        [
          {name: 'id', title: '№ заказа'},
          {
            name: 'createdon',
            title: 'Дата создания',
            getCellValue: row => (row.createdon.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$3.$2.$1')),
          },
          {name: 'status_id', title: 'Статус'},
          {name: 'sum', title: 'Сумма, руб.'},
          {name: 'fullname', title: 'ФИО'},
          {name: 'email', title: 'E-mail'},
          {name: 'phone', title: 'Телефон'},
          {name: 'payment_type', title: 'Оплата'},
          {name: 'address', title: 'Адрес'},
          {name: 'comments', title: 'Комментарии'},
          {
            name: 'actions',
            title: 'Действия',
            // getCellValue: row => (this.createActions),
            getCellValue: (row => this.createActions(row))
          },
        ],

      tableColumnExtensions: [
        {columnName: 'fullname', wordWrapEnabled: true},
        {columnName: 'payment_type', wordWrapEnabled: true},
        {columnName: 'address', wordWrapEnabled: true},
        {columnName: 'comments', wordWrapEnabled: true},
      ],

      ordersColumnWidths:
        [
          {columnName: 'id', width: 120},
          {columnName: 'createdon', width: 150},
          {columnName: 'status_id', width: 120},
          {columnName: 'sum', width: 150},
          {columnName: 'fullname', width: 150},
          {columnName: 'email', width: 150},
          {columnName: 'phone', width: 150},
          {columnName: 'payment_type', width: 150},
          {columnName: 'address', width: 200},
          {columnName: 'comments', width: 200},
          {columnName: 'actions', width: 110},
        ],

      defaultHiddenColumnNames:
        ['status_id', 'comments', 'email'],

      integratedSortingColumnExtensions:
        [
          {columnName: 'sum', compare: compareCurrency},
        ],


      rows: [],
      pageSizes: [10, 20, 50],
      currentPage: 0,
      pageSize: 10,
      leftColumns: ['id'],
      rightColumns: ['actions'],

      loading: true,
      searchValue: '',

      //-------dialogs-------
      row: {},
      sendmailOpen: false,
      delOrderOpen: false,

      lastSearch: undefined,

    }

    this.state.ordersColumnOrder = this.state.columns.map(n => (n.name))

  }

  changeCurrentPage(currentPage) {
    this.setState({currentPage})
  }

  changePageSize(pageSize) {
    this.setState({pageSize})
  }

  sendmailHandleClose() {
    this.setState({sendmailOpen: false})
  }

  deleteOrderHandleClose() {
    this.setState({delOrderOpen: false})
  }

  createActions(row) {
    const {classes} = this.props

    return <React.Fragment>

      <OrderTooltip
        title={<Typography color="inherit">Отправить email</Typography>}
        classes={classes}
      >
        <Fab
          size='small'
          color='primary'
          style={{marginRight: 10}}
          onClick={() => this.setState({row: row, sendmailOpen: true})}
        >
          <EmailIcon fontSize='small'/>
        </Fab>
      </OrderTooltip>

      <OrderTooltip
        title={
          <div style={{textAlign: 'center'}}>
            <Typography color="inherit">Удалить</Typography>
          </div>
        }
        classes={classes}
      >
        <Fab
          color='secondary'
          size='small'
          onClick={() => this.setState({row: row, delOrderOpen: true})}
        >
          <DeleteIcon fontSize='small'/>
        </Fab>
      </OrderTooltip>
    </React.Fragment>
  }

  getOrders() {

    const cfg = this.state.cfg
    const {
      searchValue,
      lastSearch
    } = this.state

    if (searchValue === lastSearch) {
      this.setState({loading: false});
      return;
    }


    this.setState({loading: true})

    let body = new FormData;
    body.append('pub_action', cfg.actions.GET_ORDERS);
    body.append('json', '1');
    body.append('limit', '0');
    body.append('sort', 'id');
    body.append('dir', 'desc');
    body.append('where', JSON.stringify({status_id: 2, 'id:like': `%${searchValue.trim()}%`}));

    fetch(cfg.connectors.site, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data.success) {

          let rows = data.results

          rows.map(r => {
            r.orderData = null
          })

          this.setState({
            rows: rows,
            loading: false,
            lastSearch: searchValue,
          })

        } else {
          Alert.error('Ошибка: ' + data.message);
          this.setState({
            loading: false,
            lastSearch: searchValue,
          })
        }
      })
      .catch((error) => {
          Alert.error('Ошибка: ' + error);
          this.setState({loading: false})
        }
      );
  }

  componentDidMount() {

    get('AdminOrdersState')
      .then(savedState => {
        if (savedState) {
          savedState = JSON.parse(savedState)

          delete savedState.columns
          savedState.searchValue=''
          savedState.lastSearch=''
          this.setState(savedState)
        }
      })

    this.getOrders()
  }

  componentDidUpdate() {

    set('AdminOrdersState', JSON.stringify(this.state))

    this.getOrders()
  }

  changeSearchValue(searchValue) {
    this.setState({
      loading: true,
      searchValue,
    });
  }

  changeOrdersColumnWidths(newColumnWidths) {
    this.setState({ordersColumnWidths: newColumnWidths})
  }

  changeOrdersColumnOrder(newOrder) {
    this.setState({ordersColumnOrder: newOrder})
  }

  changeDetailColumnWidths(newColumnWidths) {
    this.setState({detailColumnWidths: newColumnWidths})
  }

  changeDetailColumnOrder(newOrder) {
    this.setState({detailColumnOrder: newOrder})
  }

  render() {

    const {
      columns,
      rows,
      pageSize,
      pageSizes,
      currentPage,
      ordersColumnWidths,
      ordersColumnOrder,
      defaultHiddenColumnNames,
      tableColumnExtensions,
      integratedSortingColumnExtensions,
      leftColumns,
      rightColumns,
      row,
      cfg,
      loading,
      sendmailOpen,
      delOrderOpen,
    } = this.state


    return [
      <Paper
        key={'table'}
        style={{position: 'relative'}}
      >
        <Grid
          rows={rows}
          columns={columns}
        >
          <SortingState
            defaultSorting={[{columnName: 'id', direction: 'desc'}]}
          />
          <IntegratedSorting
            columnExtensions={integratedSortingColumnExtensions}
          />

          <DragDropProvider/>

          <SearchState
            onValueChange={this.changeSearchValue.bind(this)}
          />

          <RowDetailState
          />


          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={this.changeCurrentPage.bind(this)}
            pageSize={pageSize}
            onPageSizeChange={this.changePageSize.bind(this)}
          />
          <IntegratedPaging/>

          <VirtualTable
            messages={tableMessages}
            columnExtensions={tableColumnExtensions}
          />

          <TableColumnReordering
            order={ordersColumnOrder}
            onOrderChange={this.changeOrdersColumnOrder.bind(this)}
          />

          <TableColumnResizing
            columnWidths={ordersColumnWidths}
            onColumnWidthsChange={this.changeOrdersColumnWidths.bind(this)}
          />


          <TableColumnVisibility
            defaultHiddenColumnNames={defaultHiddenColumnNames}
          />
          <Toolbar/>
          <SearchPanel/>
          <ColumnChooser/>

          <TableHeaderRow showSortingControls/>
          <TableRowDetail
            contentComponent={RowDetail}
            row={row}
            cfg={cfg}
          />

          <TableFixedColumns
            leftColumns={leftColumns}
            rightColumns={rightColumns}
          />

          <PagingPanel
            pageSizes={pageSizes}
            messages={pagingPanelMessages}
          />
        </Grid>

        {loading && <Loading key='Loading'/>}

      </Paper>,

      <SendPayEmailDialog
        row={row}
        cfg={cfg}
        key={'SendPayEmailDialog'}
        open={sendmailOpen}
        sendmailHandleClose={this.sendmailHandleClose.bind(this)}
      />,

      <DeleteOrderDialog
        row={row}
        cfg={cfg}
        key={'DeleteOrderDialog'}
        open={delOrderOpen}
        deleteOrderHandleClose={this.deleteOrderHandleClose.bind(this)}
        getOrders={this.getOrders.bind(this)}
      />
    ]

  }

}

export default withStyles(styles)(AdminOrders)