import React, {Component} from 'react'
// import InputNumber from '../InputNumber'
import Stepper from 'react-stepper-primitive'
import NumberFormat from 'react-number-format'


export default function MiniBasketDetails(props) {

  let self = this

  let products = props.base.products

  if (products.length !== undefined && products.length > 0) {
    let rows = products.map(item => {

      return <tr key={item.id}>
        <td>
          <img
            src={item.thumb}
            // style={{width: 50}}
          />
        </td>
        <td>
          {item.pagetitle}
          <span style={{fontStyle: 'italic'}}>({item.is_base=='1' ? 'базовый(-ая)' : 'премиум'})</span>
        </td>
        <td>
          {item.size}
        </td>
        <td>
          <Stepper
            min={item.is_base=='1' ? 1 : 0}
            value={item.quantity}
            max={99}
            onChange={e => props.changeBasketHandler(item.id, e)}
            render={({
                       getFormProps,
                       getInputProps,
                       getIncrementProps,
                       getDecrementProps
                     }) =>
              <div {...getFormProps()} className='input-number'>
                <button {...getDecrementProps()}>-</button>
                <input {...getInputProps()} />
                <button {...getIncrementProps()}>+</button>
              </div>}
          />
        </td>
        <td>
          <NumberFormat
            // value={item.price}
            displayType={'text'}
            thousandSeparator={' '}
          />
          р.
        </td>
      </tr>

    })

    return <table className="table table-striped sost">
        <tbody>
        {rows}
        </tbody>
      </table>
  } else
    return null
}
