import React, {Component} from 'react'
import _ from 'lodash'

import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: '0 14px',
  },
  legend: {
    marginBottom: 0,
    fontSize: 14,
    color: '#333',
  },
  group: {
    margin: 0,
    flexDirection: 'row',
  },
  label: {
    fontSize: '14px',
  },
});


class SewingCategory extends Component {

  render() {
    const {classes, disabled, SewingCategoryChange, is_base} = this.props

    return <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend" className={classes.legend}>Категория пошива:</FormLabel>
      <RadioGroup
        className={classes.group}
        name="premium"
        value={is_base}
        onChange={e => SewingCategoryChange(e)}
      >

        <FormControlLabel
          value="0"
          control={<Radio color="primary"/>}
          label="премиум"
          labelPlacement="end"
          className={classes.label}
        />

        <FormControlLabel
          value="1"
          control={<Radio color="primary"/>}
          label="базовая"
          labelPlacement="end"
          disabled={disabled}
          className={classes.label}
        />
      </RadioGroup>
    </FormControl>
  }

}

export default withStyles(styles)(SewingCategory);
