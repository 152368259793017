import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Alert from 'react-s-alert';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
});


// const DialogActions = withStyles(theme => ({
//   root: {
//     borderTop: `1px solid ${theme.palette.divider}`,
//     margin: 0,
//     padding: theme.spacing.unit,
//   },
// }))(MuiDialogActions);


class SendPayEmailDialog extends Component {

  constructor(props) {

    super(props)

    this.state = {}
  }

  sendPayEmail() {

    const {
      row,
      cfg,
      sendmailHandleClose,
    } = this.props

    const {
      id,
      sum,
      email
    } = row

    let body = new FormData;
    body.append('pub_action', cfg.actions.SEND_PAY_EMAIL);
    body.append('order_id', id);
    body.append('email', email);
    body.append('sum', sum);

    fetch(cfg.connectors.site, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          Alert.success('Письмо отправлено')
          sendmailHandleClose()
        } else {

        }
      })
      .catch((error) => {
          Alert.error('Ошибка: ' + error);
          console.log('ERROR sendPayEmail:', error);
        }
      );
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (!this.props.open && nextProps.open)
      this.state.sum = nextProps.row.sum
  }


  render() {
    const {
      classes,
      open,
      row,
      sendmailHandleClose
    } = this.props;

    return (
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={sendmailHandleClose}
      >
        <DialogTitle id="max-width-dialog-title">
          <Typography
            variant="h6"
            style={{
              display: 'inline-block',
            }}
          >Коррекция заказа № {row.id}</Typography>
          <IconButton
            aria-label="Close"
            style={{
              float: 'right',
              display: 'inline-block',
              padding: 0
            }}
            onClick={sendmailHandleClose}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent>

          <DialogContentText>
            Внесите корректировки и отправьте покупателю письмо со ссылкой на оплату
          </DialogContentText>

          <form className={classes.form} noValidate>

            <FormControl className={classes.formControl}>
              <TextField
                margin="dense"
                id="sum"
                label="Сумма"
                type="number"
                value={this.state.sum}
                fullWidth
              />
            </FormControl>

          </form>

        </DialogContent>

        <DialogActions>

          <Button
            onClick={this.sendPayEmail.bind(this)}
            color="primary"
          >
            Отправить письмо
          </Button>

          <Button
            onClick={sendmailHandleClose}
            color="primary"
          >
            Закрыть
          </Button>

        </DialogActions>

      </Dialog>
    );
  }
}

SendPayEmailDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  cfg: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  sendmailHandleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(SendPayEmailDialog);