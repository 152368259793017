import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import spell from '../../misc/spell'

import MiniBasketDetails from './MiniBasketDetails'
import NumberFormat from 'react-number-format'


export default class MiniBasket extends Component {

  constructor(props) {
    super(props)

    // this.state = {
    //   opened: false
    // }
  }

  render() {
    let {sum, quantity, cfg} = this.props.base

    return ReactDOM.createPortal(
      <ul>
        <li className="menubtn" style={{minWidth: 200}}>
          <a className="click">
            <span className="fa fa-shopping-cart"></span>
            <span className="mini-shopping-cart">
            {
              quantity ?
                <div className="sum-mini">{quantity} {spell(quantity)} <span className='nowrap'>
                            <NumberFormat
                              value={sum}
                              displayType={'text'}
                              thousandSeparator={' '}
                            />
          р.
                </span></div>
                :
                <div className="sum">
                  <span className="text">Корзина пуста</span>
                </div>
            }
            </span>
          </a>

          <div className="menu_c cart_menu" id="cart">
            <div className="menu_title clearfix">
              <h4>Корзина</h4>
            </div>

            <div className="cheout_row clearfix">
              {/*<span className="fa fa-clock-o"></span>*/}
              {quantity ?
                <div className="sum-mini">
                  <a href={cfg.basket_url}>{quantity} {spell(quantity)}
                    <span
                      style={{marginLeft: 10, whiteSpace: 'nowrap'}}
                    >
                      <NumberFormat
                        value={sum}
                        displayType={'text'}
                        thousandSeparator={' '}
                      />
                      р.
                    </span>
                  </a>
                </div>
                :
                <div className="sum"><span className="text">Корзина пуста</span></div>
              }
              <MiniBasketDetails
                base={this.props.base}
                changeBasketHandler={this.props.changeBasketHandler}
              />
              <a
                href={cfg.basket_url}
                className="checkout_btn cheout_btn_mini"
              >Оформить заказ</a>

              {/*<a*/}
                {/*href="order/accept.html"*/}
                {/*className="checkout_btn"*/}
              {/*>*/}
                {/*Оформить заказ*/}
              {/*</a>*/}
            </div>
          </div>

        </li>
      </ul>,
      document.getElementById('minibasket')
    )
  }

}