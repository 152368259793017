export default function  spell(num, one='товар', two='товара', many='товаров') {

  if (num%10==1 && num%100!=11){
    return one
  }
  else if(num%10>=2 && num%10<=4 && (num%100<10 || num%100>=20)){
    return two
  }
  else{
    return many;
  }
}
