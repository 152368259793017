import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import 'babel-polyfill';

import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';

import 'whatwg-fetch'

import MiniBasket from './components/MiniBasket'
import Basket from './components/Basket'
import Kit from './components/Kit'
import OneProduct from './components/OneProduct'
import CallbackForm from './components/CallbackForm'
import AdminOrders from './components/admin/AdminOrders'

import '../scss/style.scss';
// import '../scss/s-alert-default.css';
// import '../scss/s-alert-css-effects/flip.css';

import Alert from 'react-s-alert';

// window.$ = $
// window.jQuery = $
let jGrowl = window.$.jGrowl

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#37bbec',
    },
  },
  typography: {
    useNextVariants: true,
    body: {
      fontSize: 14
    }
  },
});

class App extends Component {
  constructor(props) {

    super(props)
    this.state = {
      cfg: window.appConfig,
      products: {},
    }
  }

  componentDidMount() {
    this.getOrder()
  }

  getOrder() {

    let {cfg} = this.state


    let body = new FormData;

    // body.append('action',cfg.actions.GET_ORDER);
    body.append('action', cfg.actions.GET_ORDER);
    body.append('format', 'json');

    fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      },
      method: "POST",
      body: body,
      // body: JSON.stringify(data),
    })
      .then(function (response) {
        // console.log('response.text',response.text())
        return response.json();
      })
      .then((data) => {

        if (data.success) {
          this.setState({
            products: data.object,
            quantity: data.quantity,
            sum: data.sum,
            positions: data.positions,
          })
        } else {

        }
      })
      .catch((error) => {
          return error;
        }
      );
  }

  changeBasketHandler(id, value) {
    let body = new FormData();

    body.append('action', 'recalculate')
    // body.append('basket_action', 'recalculate')

    this.state.products.map(item => {
      body.append(
        'quantity[' + item.id + ']',
        item.id == id ? value : item.quantity
      )
    })

    fetch(this.state.cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body: body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        jGrowl(data.message)
        if (data.success)
          this.getOrder()

      })
      .catch((error) => {
          this.getOrder()
          jGrowl(error);
        }
      );
  }

  render() {

    return <MuiThemeProvider theme={theme}>

      <MiniBasket
        base={this.state}
        getOrder={this.getOrder.bind(this)}
        changeBasketHandler={this.changeBasketHandler.bind(this)}
      />

      <Basket
        base={this.state}
        getOrder={this.getOrder.bind(this)}
        changeBasketHandler={this.changeBasketHandler.bind(this)}
      />

      <Kit
        base={this.state}
        getOrder={this.getOrder.bind(this)}
      />

      <OneProduct
        base={this.state}
        getOrder={this.getOrder.bind(this)}
      />

      <CallbackForm
        base={this.state}
      />

      <Alert
        stack={{limit: 12}}
        effect='flip'
        timeout={5000}
        html={true}
        style={{zIndex: 11111}}
      />

    </MuiThemeProvider>
  }
}


let e = document.getElementById('minibasket')
if (e)
  ReactDOM.render(
    <App/>
    , e
  )


e = document.getElementById('admin_orders')
if (e)
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <AdminOrders/>
      <Alert
        stack={{limit: 12}}
        effect='flip'
        timeout={5000}
        html={true}
      />
    </MuiThemeProvider>
    , e
  )
