import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

let jGrowl = window.$.jGrowl

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}


function MyTextField(props) {
  return <TextField
    {...props}
    inputProps={{
      style: {
        fontSize: 14,
      }
    }}
    InputLabelProps={{
      style: {
        fontSize: 14,
      }
    }}

  />

}


export default class CallbackForm extends Component {

  constructor(props) {
    super(props)

    this.state = {
      sended:false,
      errors: {},
      fullname:'',
      email:'',
      message:'',
    }
  }


  sendForm() {
    let {cfg} = this.props.base
    let {fullname, email, message} = this.state

    let body = new FormData;
    body.append('pub_action', cfg.actions.FORM_FEEDBACK);
    body.append('format', 'json');
    body.append('fullname', fullname)
    body.append('email', email)
    body.append('message', message)

    let sended=false
    fetch(cfg.connectors.site, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        let err = {}

        if (data.success) {
          jGrowl('Ваше сообщение отправлено. Спасибо за обращение.')
          sended=true
        } else {
          jGrowl(data.message)
          data.data.map(n => {
            err[n.id] = n.msg
          })

        }
        this.setState({errors: err, sended})
      })
      .catch((error) => {
          return error;
        }
      );
  }

  render() {

    let callback_form = document.getElementById('callback_form');

    if (!callback_form)
      return null

    //else
    let {errors} = this.state

    return ReactDOM.createPortal(
      !this.state.sended?
        <div
          style={styles.formContainer}
        >
          <h5>Напишите нам</h5>
          
          <MyTextField
            required
            label="Как к Вам обращаться"
            margin="normal"
            value={this.state.fullname}
            onChange={e => this.setState({fullname: e.target.value})}
            error={errors.fullname}
          />

          <MyTextField
            required
            label="Электронная почта"
            margin="normal"
            value={this.state.email}
            onChange={e => this.setState({email: e.target.value})}
            error={errors.email}
          />

          <MyTextField
            required
            label="Сообщение"
            margin="normal"
            multiline
            value={this.state.message}
            onChange={e => this.setState({message: e.target.value})}
            rowsMax="10"
            placeholder="Ваш вопрос или пожелание"
            error={errors.message}
          />

          <Button className="btn"
                  variant={'raised'}
                  color={'primary'}
                  style={{fontSize: 14, marginTop:'1rem'}}
                  onClick={this.sendForm.bind(this)}
          >
            Отправить
          </Button>
        </div>
        :
        <h3>Ваше сообщение отправлено. Спасибо за обращение.</h3>
      ,
      callback_form
    )
  }
}