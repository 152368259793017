import React, {Component} from 'react'
import ReactDOM from 'react-dom'
// import spell from '../../misc/spell'
import Stepper from 'react-stepper-primitive'
import NumberFormat from 'react-number-format'

// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

let jGrowl = window.$.jGrowl

import SewingCategory from './components/SewingCategory'

export default class Kit extends Component {

  constructor(props) {
    super(props)

    let kitroot = document.getElementById('kit_order')
    let id = kitroot ? kitroot.dataset.id : null

    this.state = {
      category_id: id,
      kit: null,
      products: [],
    }

  }

  componentDidMount() {
    this.getKit()
  }

  getKit() {

    if (!this.state.category_id)
      return;

    let {cfg} = this.props.base

    let body = new FormData;
    body.append('category_id', this.state.category_id);
    body.append('where', '{"id":"' + this.state.category_id + '"}');
    body.append('showhidden', 1);
    body.append('current', 1);
    body.append('pub_action', cfg.actions.GET_PRODUCTS);
    body.append('format', 'json');

    fetch(cfg.connectors.site, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      },
      method: "POST",
      body: body,
      // body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data.success) {

          let kit = data.object

          let body2 = new FormData;
          body2.append('kit_id', this.state.category_id);
          body2.append('showhidden', 1);
          body2.append('pub_action', cfg.actions.GET_KIT_PRODUCTS);
          body2.append('format', 'json');
          
          fetch(cfg.connectors.site, {
            credentials: 'same-origin',
            headers: {
              'Accept': 'application/json',
              // 'Content-Type': 'application/json'
            },
            method: "POST",
            body: body2,
            // body: JSON.stringify(data),
          })
            .then(function (response) {
              return response.json();
            })
            .then((data) => {

              if (data.success) {

                let products = data.object;


                for (let i in products) {
                  products[i].quantity = 0
                  products[i].size = products[i].opts[0].size
                  products[i].price = products[i].opts[0].price
                }

                this.setState({
                  is_base: '0',
                  kit,
                  products,
                })
              } else {

              }
            })
            .catch((error) => {
                return error;
              }
            );


        } else {

        }
      })
      .catch((error) => {
          return error;
        }
      );

  }

  SewingCategoryChange(e) {
    let st = _.cloneDeep(this.state)
    st.is_base = e.target.value

    for (let i in st.products) {
      if (st.is_base == '1' && st.products[i].quantity < 1)
        st.products[i].quantity = 1
    }

    this.setState(st)
  }

  changeQuantity(id, quantity) {
    let products = _.cloneDeep(this.state.products)
    products[id].quantity = quantity
    this.setState({products});
  }

  changeSize(id, ev) {
    let products = _.cloneDeep(this.state.products)
    products[id].size = ev.target.value

    products[id].opts.find(i => {
      if (i.size == ev.target.value) {
        products[id].price = i.price
        return true
      }
    })

    this.setState({products});
  }

  async addProduct(product) {
    let {cfg} = this.props.base

    let body = new FormData;
    body.append('action', cfg.actions.ADD_PRODUCT);
    body.append('format', 'json');
    body.append('is_base', this.state.is_base);
    body.append('quantity', product.quantity);
    body.append('product_id', product.product_id);
    body.append('size', product.size);

    await fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        jGrowl(data.message)
        this.props.getOrder()
      })
      .catch((error) => {
          return error;
        }
      );
  }

  toOrder() {
    let products = _.cloneDeep(this.state.products)

    let {is_base} = this.state

    for (let p in products) {
// console.log('state', is_base, products[p].have_base)
      if ((is_base=='1' && products[p].have_base=='1' || is_base!='1') && products[p].quantity) {
        this.addProduct(products[p])
        products[p].quantity = 0
      }
    }
    this.setState({products})
    this.props.getOrder()
  }


  kitSumm(products = this.state.products) {
    let summ = 0;
    for (let i in products) {
      let q = typeof products[i].quantity != 'undefined' ? products[i].quantity : 0
      let p = typeof products[i].price != 'undefined' ? products[i].price : 0

      if (this.state.is_base != '1' || this.state.is_base == '1' && products[i].have_base == '1')
        summ += p * q * (this.state.is_base=='1' ? .7 : 1)
    }
    return summ;
  }


  render() {

    let kit_order = document.getElementById('kit_order');

    if (!kit_order)
      return null

    //else
    let {products, kit, is_base} = this.state

    let {delivery, sum, quantity} = this.props.base

    let rows = [];

    for (let item in products) {
      let product = products[item]
      let i = 0;
      let opts = []

      if (typeof product.opts !== 'undefined' && product.opts.length) {

        opts = product.opts.map(o => {
          if (!i)
          // price = o.price

            return <MenuItem
              key={o.size}
              value={o.size}
              style={{fontSize: 14}}
            >
              {o.size}
            </MenuItem>
        })
      }

      rows.push(<fieldset
          id={'id_' + product.id}
          data-id={product.id}
          key={product.id}
          className={is_base == '1' && product.have_base != '1' ? "no_base" : ''}
        >
          <h5>{product.pagetitle}</h5>
          <div className="desc_blk_bot clearfix">
            <div className="option-c">

              {opts.length ?
                // Размер:

                <FormControl>
                  <Select
                    value={product.size}
                    onChange={e => this.changeSize(product.id, e)}
                    inputProps={{
                      // name: 'age',
                      id: 'age-simple',
                    }}
                    style={{fontSize: 14}}
                  >
                    {opts}
                  </Select>
                </FormControl>
                :
                null
              }

            </div>

            <div className="price-c">
              <NumberFormat
                value={is_base != '1' ? product.price : parseInt(product.price * .7)}
                displayType={'text'}
                thousandSeparator={' '}
              />
              р.
            </div>

            <Stepper
              min={is_base == '1' ? 1 : 0}
              value={product.quantity}
              max={99}
              onChange={e => this.changeQuantity(product.id, e)}
              render={({
                         getFormProps,
                         getInputProps,
                         getIncrementProps,
                         getDecrementProps
                       }) =>
                <div {...getFormProps()} className='input-number'>
                  <button {...getDecrementProps()}>-</button>
                  <input {...getInputProps()} />
                  <button {...getIncrementProps()}>+</button>
                </div>}
            />
          </div>

          <br className="clear"/>
          <hr/>
        </fieldset>
      )
    }

    let kitSumm = this.kitSumm()
    let summ = (typeof this.props.base.sum != 'undefined' ? this.props.base.sum : 0) + kitSumm;
    return kit ?
      ReactDOM.createPortal(
        [
          <div className="desc_top clearfix" key={'material_color'}>
            <span> Материал: {kit.tvs.material.value}</span> <br/>
            <span> Цвет: {kit.tvs.color.value}</span> <br/>
          </div>,

          <SewingCategory
            disabled={kit.have_base == '0'}
            is_base={is_base}
            SewingCategoryChange={this.SewingCategoryChange.bind(this)}
            key={'SewingCategory'}
          />,

          <div
            key={'on_storage'}
            id={'on_storage'}
            className={"desc_top"}
          >
            {is_base != '1' && kit.store_premium != '1' || is_base == '1' && kit.store_base != '1' ?
              'Нет на складе'
              :
              null
            }
          </div>,

          <div className='rows'>
            {rows}
          </div>,

          <div
            key={'kit_summ'}
            className="full_summ"
            style={{paddingBottom: 0}}
          >
            Итого: <span>
            <NumberFormat
              value={kitSumm}
              displayType={'text'}
              thousandSeparator={' '}
            />
            </span>р.
          </div>,

          <div
            key={'full_summ'}
            // className="full_summ"
            style={{fontStyle: 'italic', margin: '-5px 0 10px'}}
          >
            (в корзине будет на сумму <span>
            <NumberFormat
              value={summ}
              displayType={'text'}
              thousandSeparator={' '}
            />
            </span>р.)
          </div>,

          <button
            key={'order_btn'}
            className="btn_ct"
            onClick={this.toOrder.bind(this)}
          >
            В корзину
          </button>
        ]
        ,
        kit_order
      )
      :
      null

  }
}