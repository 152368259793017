import React from 'react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Fab from '@material-ui/core/Fab';
// import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from 'react-s-alert';
import NumberFormat from 'react-number-format';

import {withStyles} from '@material-ui/core/styles'

import {
  SummaryState,
  CustomSummary,
  IntegratedSummary,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  DragDropProvider,
  TableColumnReordering,
  TableColumnResizing,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';

import {
  OrderTooltip,
  Loading,
  tableMessages,
  summaryRowMessages,
} from '../index'


const styles = theme => ({
  detail_img: {
    width: 80,
  },
})

const CurrencyFormatter = ({value}) => (
  <NumberFormat
    value={value}
    displayType='text'
    thousandSeparator=' '
  />)

const CurrencyTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={CurrencyFormatter}
    {...props}
  />
)


class RowDetail extends React.PureComponent {
  constructor(props) {
    super(props)
    console.log('RowDetail', props)
    this.state = {
      columns: [
        {
          name: 'img',
          title: 'Фото',
          getCellValue: row => (<a href={row.uri} target='_blank'><img src={`assets/images/${row.img}`}
                                                                       className={props.classes.detail_img}/></a>),
        },
        {
          name: 'pagetitle',
          title: 'Товар',
          getCellValue: row => (<a href={row.uri} target='_blank'>{row.pagetitle}</a>),
        },
        {
          name: 'size',
          title: 'Размер',
        },
        {
          name: 'is_base',
          title: 'Категория',
          getCellValue: row => (row.is_base == '0' ? 'Премиум' : 'Базовый'),
        },
        {
          name: 'quantity',
          title: 'Кол-во',
        },
        {
          name: 'price',
          title: 'Цена, руб.',
          // getCellValue: row => (<NumberFormat value={row.price} displayType='text' thousandSeparator=' ' />),
        },
        {
          name: 'summ',
          title: 'Сумма, руб.',
          getCellValue: row => (row.price * row.quantity),
        },
        {
          name: 'actions',
          title: 'Действия',
          getCellValue: (row => this.createActions(row))
        },
      ],

      columnWidths: [
        {columnName: 'img', width: 120},
        {columnName: 'pagetitle', width: 300},
        {columnName: 'size', width: 120},
        {columnName: 'is_base', width: 120},
        {columnName: 'quantity', width: 120},
        {columnName: 'price', width: 120},
        {columnName: 'summ', width: 120},
        {columnName: 'actions', width: 120},
      ],

      tableColumnExtensions: [
        {columnName: 'quantity', align: 'right'},
        {columnName: 'price', align: 'right'},
        {columnName: 'summ', align: 'right'},
      ],

      totalSummaryItems: [
        {columnName: 'quantity', type: 'sum'},
        {columnName: 'summ', type: 'sum'},
      ],

      currencyColumns: ['price', 'summ'],

      rows: [],

      loading: false,

    }

    this.state.columnOrder = this.state.columns.map(n => (n.name))
  }

  getTotalSummaryValues() {
    const {
      rows,
      totalSummaryItems
    } = this.state;

    rows.map(r => r.summ = r.price * r.quantity)

    return totalSummaryItems.map((summary) => {
      const {
        columnName,
        type
      } = summary;

      return IntegratedSummary.defaultCalculator(type, rows, row => row[columnName]);
    });
  }

  changeColumnWidths(newColumnWidths) {
    this.setState({columnWidths: newColumnWidths})
  }

  changeColumnOrder(newOrder) {
    this.setState({columnOrder: newOrder})
  }


  createActions(row) {
    const {classes} = this.props

    return <React.Fragment>

      <OrderTooltip
        title={
          <div style={{textAlign: 'center'}}>
            <Typography color="inherit">Удалить</Typography>
          </div>
        }
        classes={classes}
      >
        <Fab
          color='secondary'
          size='small'
          // onClick={() => this.setState({row: row, delOrderOpen: true})}
        >
          <DeleteIcon fontSize='small'/>
        </Fab>
      </OrderTooltip>
    </React.Fragment>
  }


  getOrderDetail() {

    const {
      row,
    } = this.props

    const cfg = window.appConfig

    const {
      id: order_id,
      orderData,
    } = row

    if (orderData) {
      this.setState({rows: orderData})
      return
    }

    this.setState({loading: true})

    let body = new FormData
    body.append('pub_action', cfg.actions.ORDER_DETAILS)
    body.append('order_id', order_id)
    body.append('raw', 1)
    body.append('where', JSON.stringify({'quantity:>': 0}));

    fetch(cfg.connectors.site, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          row.orderData = data.object
          this.setState({rows: data.object, loading: false})

        } else {
          Alert.error('Ошибка: ' + data.message);
          this.setState({loading: false})
        }
      })
      .catch((error) => {
          Alert.error('Ошибка: ' + error);
          this.setState({loading: false})
        }
      );
  }


  componentDidMount() {
    const {
      row
    } = this.props

    this.getOrderDetail(row)

  }


  render() {
    const {
      rows,
      columns,
      columnOrder,
      columnWidths,
      loading,
      totalSummaryItems,
      tableColumnExtensions,
      currencyColumns,
    } = this.state

    const {
      row
    } = this.props

    return [
      <Paper
        key={'table'}
        style={{position: 'relative'}}
      >
        <Typography variant={'h5'}>Детали заказа № {row.id}</Typography>

        <Grid
          rows={rows}
          columns={columns}
        >

          <DragDropProvider/>

          <CurrencyTypeProvider
            for={currencyColumns}
          />

          <SummaryState totalItems={totalSummaryItems}/>
          <CustomSummary totalValues={this.getTotalSummaryValues()}/>

          <Table
            messages={tableMessages}
            columnExtensions={tableColumnExtensions}
          />

          <TableColumnReordering
            order={columnOrder}
            onOrderChange={this.changeColumnOrder.bind(this)}
          />

          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={this.changeColumnWidths.bind(this)}
          />

          <TableHeaderRow/>
          <TableSummaryRow
            messages={summaryRowMessages}
          />

        </Grid>

        {loading && <Loading key='Loading'/>}

      </Paper>,

    ]


  }

}

export default withStyles(styles)(RowDetail)