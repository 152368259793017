import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import spell from '../../misc/spell'
import Stepper from 'react-stepper-primitive'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import NumberFormat from 'react-number-format'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles'

let jGrowl = window.$.jGrowl

const styles = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  body2: {
    fontSize: 40,
  }
}


function MyTextField(props) {
  return <TextField
    {...props}
    inputProps={{
      style: {
        fontSize: 14,
      }
    }}
    InputLabelProps={{
      style: {
        fontSize: 14,
      }
    }}

  />

}


class Basket extends Component {

  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      policyChecked: false,
      payData: null,
    }
  }

  removeProduct(product_id) {

    let {cfg} = this.props.base

    let body = new FormData;
    body.append('action', cfg.actions.REMOVE_PRODUCT);
    body.append('format', 'json');
    body.append('product_key', product_id);

    fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        jGrowl(data.message)
        this.props.getOrder()
        if (data.success) {

        } else {

        }
      })
      .catch((error) => {
          return error;
        }
      );
  }

  emptyBasket() {
    let {cfg} = this.props.base

    let body = new FormData;
    body.append('action', cfg.actions.EMPTY_BASKET);
    body.append('format', 'json');

    fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        jGrowl(data.message)
        this.props.getOrder()
      })
      .catch((error) => {
          return error;
        }
      );
  }

  acceptOrder() {
    let {cfg} = this.props.base
    let {fullname, email, phone, address, payment_type, certificate, comments} = this.state

    let body = new FormData;
    body.append('action', cfg.actions.ORDER_SUBMIT);
    body.append('format', 'json');
    body.append('fullname', fullname)
    body.append('email', email)
    body.append('phone', phone)
    body.append('address', address)
    body.append('payment_type', payment_type)
    body.append('certificate', certificate ? certificate : '')
    body.append('comments', comments ? comments : '')

    fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        let err = {}

        if (data.success) {

          jGrowl(data.message)
          this.getHMAC();

        } else {
          jGrowl(data.message)
          data.data.map(n => {
            err[n.id] = n.msg
          })

        }
        this.setState({errors: err})
        this.props.getOrder()
      })
      .catch((error) => {
          return error;
        }
      );
  }


  getHMAC() {
    const {cfg} = this.props.base

    let res = null;

    let body = new FormData;
    body.append('action', cfg.actions.GET_HMAC);
    body.append('format', 'json');

    fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        let err = {}

        if (data.success) {
          this.setState({payData: data.object})
        }
      })
      .catch((error) => {
          console.log('ERROR', error)
        }
      )
  }


  render() {

    const {
      classes,
      base
    } = this.props

    let {delivery, sum, quantity, products} = base

    let basket = document.getElementById('full_basket')

    if (!basket)
      return null;

    //else
    let {
      errors,
      policyChecked,
      payData
    } = this.state

    var rows;

    if (typeof products.length !== 'undefined' && products.length > 0) {

      rows = products.map(item => {
        return <tr key={item.id}>
          <td>
            <img src={item.thumb} alt={item.pagetitle}/>
          </td>

          <td>
            {item.pagetitle}
            <span
              style={{fontStyle: 'italic'}}
            >
                ({item.is_base == '1' ? 'базовый(-ая)' : 'премиум'})
              </span>
          </td>

          <td>
            {item.size}
          </td>

          <td>
            <Stepper
              min={item.is_base == '1' ? 1 : 0}
              value={item.quantity}
              max={99}
              onChange={e => this.props.changeBasketHandler(item.id, e)}
              render={({
                         getFormProps,
                         getInputProps,
                         getIncrementProps,
                         getDecrementProps
                       }) =>
                <div {...getFormProps()} className='input-number'>
                  <button {...getDecrementProps()}>-</button>
                  <input {...getInputProps()} />
                  <button {...getIncrementProps()}>+</button>
                </div>}
            />
          </td>

          <td className="cost">
            <NumberFormat
              value={item.price}
              displayType={'text'}
              thousandSeparator={' '}
            />
          </td>
          <td>
            {item.is_base != '1' ?
              <Fab
                aria-label="Delete"
                style={{width: 30, height: 30, minHeight: 30}}
                onClick={e => this.removeProduct(item.id)}
              >
                <DeleteIcon
                  style={{fontSize: 18, color: '#999'}}
                />
              </Fab>
              :
              null
            }
          </td>
        </tr>
      })
    }


// console.log('pay_data', payData)

    return ReactDOM.createPortal(
      payData ?
        <div>
          <h3>Спасибо, что выбрали Ателье постельного белья «Разговор подушек»!</h3>
          <p>Ваш заказ оформлен. Детали заказа Вы получите на свою электронную почту.<br/>
            В ближайшее время наш менеджер свяжется с Вами для подтверждения заказа и сроков доставки.</p>
        </div>
        :
        products.length > 0 ?
          [
            <table className="table table-striped sost" key={'details'}>
              <thead>
              <tr>
                <td className="title-basket">Изображение</td>
                <td className="title-basket" style={{width: '30%'}}>Наименование</td>
                <td className="title-basket" style={{width: '15%'}}>Размер</td>
                <td className="title-basket" style={{width: '15%'}}>Количество</td>
                <td className="title-basket" style={{width: '16%'}}>Цена, руб</td>
                <td className="title-basket" style={{width: '8%'}}>Удалить</td>
              </tr>
              </thead>
              <tbody>
              {rows}
              </tbody>
            </table>,

            <div className="row" key={'summary'}>
              <div className="col-lg-4 col-offset-8">

                <div className="order_data">
                  <p>В корзине: <span className="num">{quantity}</span> <span
                    className="text">{spell(quantity)}</span></p>
                  <p>Сумма заказа: <span className="cost">{sum}</span> руб.</p>
                  <p id="delivery_summ">
                    {delivery && item.sum ?
                      'Стоимость доставки включена: 250 руб.'
                      :
                      null
                    }
                  </p>
                </div>

              </div>
            </div>,

            <div className="row" key={'actions'}>
              <div className="col-lg-4 col-offset-8">

                <div className="order_control">

                  <Button className="btn"
                          variant={'contained'}
                          style={{fontSize: 14, marginTop: 20}}
                          onClick={this.emptyBasket.bind(this)}
                  >
                    <span>Очистить корзину</span>
                  </Button>
                </div>

              </div>
            </div>,

            <div
              key={'accept_form'}
              style={styles.formContainer}
              id={'no_c'}
            >

              <MyTextField
                required
                label="Как к Вам обращаться"
                margin="normal"
                value={this.state.fullname}
                onChange={e => this.setState({fullname: e.target.value})}
                error={errors.fullname}
              />

              <MyTextField
                required
                label="Электронная почта"
                margin="normal"
                value={this.state.email}
                onChange={e => this.setState({email: e.target.value})}
                error={errors.email}
              />

              <MyTextField
                label="Телефон"
                margin="normal"
                value={this.state.phone}
                onChange={e => this.setState({phone: e.target.value})}
              />

              <MyTextField
                required
                label="Адрес доставки"
                margin="normal"
                multiline
                value={this.state.address}
                onChange={e => this.setState({address: e.target.value})}
                rowsMax="10"
                error={errors.address}
              />

              <FormControl
                component="fieldset"
                style={{marginTop: 20}}
              >
                <FormLabel
                  component="legend"
                  style={{
                    fontSize: 14,
                    margin: '20px 0 0',
                  }}
                >
                  Способ оплаты
                </FormLabel>

                <RadioGroup
                  id='basket_pay_method'
                  aria-label="gender"
                  name="gender2"
                  value={this.state.payment_type}
                  onChange={e => this.setState({payment_type: e.target.value})}
                  required
                >
                  <FormControlLabel
                    value="наличными"
                    control={<Radio color="primary"/>}
                    label=<span style={{fontSize: 14}}>наличными</span>
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="картой курьеру"
                    control={<Radio color="primary"/>}
                    label=<span style={{fontSize: 14}}>картой курьеру</span>
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="электронный платёж"
                    control={<Radio color="primary"/>}
                    label=<span style={{fontSize: 14}}>электронный платёж</span>
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>

              <MyTextField
                label="Если у Вас есть «Подарочный сертификат», то введите КОД:"
                margin="normal"
                value={this.state.certificate}
                onChange={e => this.setState({certificate: e.target.value})}
              />

              <MyTextField
                label="Промокод"
                margin="normal"
                value={this.state.promo}
                onChange={e => this.setState({promo: e.target.value})}
              />

              <MyTextField
                label="В какое время Вам удобно принять звонок от менеджера для подтверждения заказа?"
                margin="normal"
                multiline
                value={this.state.comments}
                onChange={e => this.setState({comments: e.target.value})}
                rowsMax="4"
              />
            </div>,

            <div
              key={'policy'}
              style= {{
                marginTop: 30,
              }}
            >
              <Switch
                checked={policyChecked}
                onClick={e => this.setState({policyChecked: e.target.checked})}
                value="policyChecked"
                color="primary"
              />
              Нажимая кнопку «Оформить» Вы соглашаетесь с
              «
              <a
                href="http://razgovorpodushek.ru/common/publichnaya-oferta-o-predostavlenii-uslug.html"
                target="_blank"
              >
                Пользовательским соглашением
              </a>
              »
            </div>,

            <Button className="btn"
                    key={'order-btn'}
                    variant={'contained'}
                    disabled={!this.state.policyChecked}
                    color={'primary'}
                    style={{fontSize: 14}}
                    onClick={this.acceptOrder.bind(this)}
            >
              {'Оформить заказ'}
            </Button>,
          ]
          :
          <h3>Корзина пуста</h3>

      , basket
    )
  }
}

export default withStyles(styles)(Basket)