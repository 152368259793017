import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Alert from 'react-s-alert';

const styles = theme => ({
  root: {
    zIndex:1000
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
  },
});


// const DialogActions = withStyles(theme => ({
//   root: {
//     borderTop: `1px solid ${theme.palette.divider}`,
//     margin: 0,
//     padding: theme.spacing.unit,
//   },
// }))(MuiDialogActions);


class DeleteOrderDialog extends Component {

  constructor(props) {

    super(props)

    this.state = {}
  }


  deleteOrder() {

    const {
      row,
      cfg,
      deleteOrderHandleClose,
      getOrders,
    } = this.props

    const {
      id,
      sum,
      email
    } = row

    let body = new FormData;
    body.append('pub_action', cfg.actions.ORDER_DELETE);
    body.append('order_id', id);

    fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          Alert.success(data.message);
          deleteOrderHandleClose()
          getOrders();
        }
        else {
          Alert.error('Ошибка: '+data.message);
        }
      })
      .catch((error) => {
          Alert.error('Ошибка: '+error);
          console.log('ERROR deleteOrder:', error);
        }
      );
  }


  render() {
    const {
      classes,
      open,
      deleteOrderHandleClose,
      row,
    } = this.props;

    const {id} = row

    return (
      <Dialog
        maxWidth='xs'
        open={open}
        onClose={deleteOrderHandleClose}
        className={classes.root}
      >
        <DialogTitle id="max-width-dialog-title">
          <Typography
            variant="h6"
            style={{
              display: 'inline-block',
            }}
          >Удаление заказа</Typography>
          <IconButton
            aria-label="Close"
            style={{
              float: 'right',
              display: 'inline-block',
              padding: 0
            }}
            onClick={deleteOrderHandleClose}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent>

          <DialogContentText>
            Удалить заказ № {id}?
          </DialogContentText>

        </DialogContent>

        <DialogActions>

          <Button
            onClick={this.deleteOrder.bind(this)}
            color="primary"
          >
            Удалить
          </Button>

          <Button
            onClick={deleteOrderHandleClose}
            color="primary"
          >
            Нет
          </Button>

        </DialogActions>

      </Dialog>
    );
  }
}

DeleteOrderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  cfg: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  deleteOrderHandleClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(DeleteOrderDialog);