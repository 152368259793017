import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Stepper from 'react-stepper-primitive'
import NumberFormat from 'react-number-format'

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

let jGrowl = window.$.jGrowl

export default class OneProduct extends Component {

  constructor(props) {
    super(props)

    let root = document.getElementById('product_order')
    let id = root ? root.dataset.id : null

    this.state = {
      product: null,
      product_id: id,
    }

  }

  componentDidMount() {
    this.getProduct()
  }

  getProduct() {

    let {cfg} = this.props.base

    let body = new FormData;
    body.append('product_id', this.state.product_id);
    body.append('showhidden', 1);
    body.append('current', 1);
    body.append('pub_action', cfg.actions.GET_PRODUCT);
    body.append('format', 'json');

    fetch(cfg.connectors.site, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      },
      method: "POST",
      body: body,
      // body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {

        if (data.success) {

          let product=data.object
          product.quantity = 0
          product.size = product.opts[0].size
          product.price = product.opts[0].price

          this.setState({product})

        } else {

        }
      })
      .catch((error) => {
          return error;
        }
      );
    
  }

  changeQuantity(id, quantity) {
    let product = _.cloneDeep(this.state.product)
    product.quantity = quantity
    this.setState({product});
  }

  changeSize(ev) {
    let product = _.cloneDeep(this.state.product)
    product.size = ev.target.value

    product.opts.find(i => {
      if (i.size == ev.target.value) {
        product.price = i.price
        return true
      }
    })

    this.setState({product});
  }

  async addProduct() {
    let {cfg} = this.props.base
    let product = _.cloneDeep(this.state.product)

    if(!product.quantity)
      return

    let body = new FormData;
    body.append('action', cfg.actions.ADD_PRODUCT);
    body.append('format', 'json');
    body.append('quantity', product.quantity);
    body.append('product_id', product.product_id);
    body.append('size', product.size);

    await fetch(cfg.connectors.basket, {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
      },
      method: "POST",
      body,
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        jGrowl(data.message)
        product.quantity = 0
        this.setState({product})
        this.props.getOrder()
      })
      .catch((error) => {
          return error;
        }
      );
  }

  render() {

    let product_order = document.getElementById('product_order');

    if (!product_order)
      return null

    //else
    let {product} = this.state
    if(!product)
      return null

    let opts = []
    let i=0

    if (typeof product.opts !== 'undefined' && product.opts.length) {

      opts = product.opts.map(o => {
        if (!i)
        // price = o.price

          return <MenuItem
            key={o.size}
            value={o.size}
            style={{fontSize: 14}}
          >
            {o.size}
          </MenuItem>
      })
    }

    let row = <fieldset
          id={'id_' + product.id}
          data-id={product.id}
          key={product.id}
        >
          <h5>{product.pagetitle}</h5>
          <div className="desc_blk_bot clearfix">
            <div className="option-c">

              {opts.length ?
                // Размер:

                <FormControl>
                  <Select
                    value={product.size}
                    onChange={e => this.changeSize(e)}
                    inputProps={{
                      // name: 'age',
                      id: 'age-simple',
                    }}
                    style={{fontSize: 14}}
                  >
                    {opts}
                  </Select>
                </FormControl>
                :
                null
              }

            </div>

            <div className="price-c">
              <NumberFormat
                value={product.price}
                displayType={'text'}
                thousandSeparator={' '}
              />
              р.
            </div>

            <Stepper
              min={0}
              value={product.quantity}
              max={99}
              onChange={e => this.changeQuantity(product.id, e)}
              render={({
                         getFormProps,
                         getInputProps,
                         getIncrementProps,
                         getDecrementProps
                       }) =>
                <div {...getFormProps()} className='input-number'>
                  <button {...getDecrementProps()}>-</button>
                  <input {...getInputProps()} />
                  <button {...getIncrementProps()}>+</button>
                </div>}
            />
          </div>


          <br className="clear"/>
          <hr/>
        </fieldset>

    let productSumm = product.quantity * product.price
    let summ = (typeof this.props.base.sum != 'undefined' ? this.props.base.sum : 0) + productSumm;
    return ReactDOM.createPortal(
        [
          <div className="desc_top clearfix" key={'material_color'}>
            <span> Материал: {product.tvs.material.value}</span> <br/>
            <span> Цвет: {product.tvs.color.value}</span> <br/>
          </div>,

          <div
            key={'on_storage'}
            id={'on_storage'}
            className={"desc_top"}
          >
            {product.store_premium != '1' ?
              'Нет на складе'
              :
              null
            }
          </div>,

          row,

          <div
            key={'product_summ'}
            className="full_summ"
            style={{paddingBottom: 0}}
          >
            Итого: <span>
            <NumberFormat
              value={productSumm}
              displayType={'text'}
              thousandSeparator={' '}
            />
            </span>р.
          </div>,

          <div
            key={'full_summ'}
            style={{fontStyle: 'italic', margin: '-5px 0 10px'}}
          >
            (в корзине будет на сумму <span>
            <NumberFormat
              value={summ}
              displayType={'text'}
              thousandSeparator={' '}
            />
            </span>р.)
          </div>,

          <button
            key={'order_btn'}
            className="btn_ct"
            onClick={this.addProduct.bind(this)}
          >
            В корзину
          </button>
        ]
        ,
        product_order
      )
  }

}